.cotizaciones-grid {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 0.5rem;
    padding: 0 1rem;
}

.row-titles,
.row-values {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    border-bottom: 1px solid var(--shadow-gray);
    padding: 0.5rem 0;
}

.row-titles>div,
.row-values>div:first-child {
    font-weight: 600;
}

.row-values>div:nth-child(odd) {
    display: none;
}

.row-values a {
    color: var(--light-blue)
}

@media (max-width: 768px) {
    .row-titles {
        display: none;
    }

    .row-values {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        row-gap: 0.5rem;
        border: 1px solid #ccc;
        padding: 1rem;
        border-radius: 5px;
    }

    .row-values .strong {
        font-weight: 600;
    }

    .row-values>div:nth-child(odd) {
        display: block;
        grid-column: 1 / 2;
    }

    .row-values>div:nth-child(even) {
        grid-column: 2 / 3;
    }
}
.main-container {
	max-width: 1300px;
	margin: 0 auto;
}

.container {
	display: grid;
	grid-template-columns: 1fr 300px;
	column-gap: 2rem;
	margin: 1rem 0;
}

.detail-left {
	width: 100%;
	grid-column: 1 / 2;
	padding-right: 1rem;
}

.detail-right {
	width: 100%;
	grid-column: 2 / 3;
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
}

.banner {
	width: 100%;
}


@media (max-width: 980px) {
	.container {
		grid-template-columns: 1fr;
	}

	.btn-load-more {
		width: 90%;
		margin: 0 auto;
	}

	.detail-left {
		width: 100%;
		padding: 0;
		grid-column: 1 / 1;
	}

	.detail-right {
		width: 100%;
		padding-top: 0.938rem;
		grid-column: 1 / 1;
	}

	.column-widget-container-parent {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}

	.author-container {
		margin: 3.125rem 0rem 1rem 0rem;
	}
}

@media (max-width: 650px) {
	.column-widget-container-parent {
		grid-template-columns: 1fr;
	}

	.container-author-social-media {
		flex-direction: column;
	}
}



@media (max-width: 480px) {

	.author-container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 3.125rem 0rem 1rem 0rem;
	}

	.author-a img {
		width: 5.75rem;
		margin: 0;
	}

	.author-name {
		margin: 0;
		align-items: center;
	}

	.author-name h1 {
		font-size: 1.1rem;
		margin: 0 0 1rem 0;
	}

	.author-name a,
	.author-name p {
		font-size: .8rem;
	}

	.container-icon-social-media {
		justify-content: center;
		align-items: center;
	}
}
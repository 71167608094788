:root {
	--streetSizeH: 2rem;
	--containerWidth: 1300px;
    --streetSizeV:1rem;
}

@media (max-width: 980px) {
	:root {
        --streetSizeH: 2rem;
    }
}

@media (max-width: 650px) {
	:root {
        --streetSizeH: 2rem;
    }
}

.PUBLISHED .CONTAINER-WRAPPER {
	max-width: calc(var(--containerWidth) + var(--streetSizeH));

}

.PUBLISHED .WIDGET-BOX {
	padding-left: calc(var(--streetSizeH) / 2) !important;
    padding-right: calc(var(--streetSizeH) / 2) !important;
    padding-top: calc(var(--streetSizeV) / 2) !important;
    padding-bottom: calc(var(--streetSizeV) / 2) !important;
}


.new-sidepanel {
	width: 20rem;
	max-width: 20rem;
	background-color: var(--white);
	position: fixed;
	top: 0;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	z-index: 200;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	transition: transform 3s ease-in-out;
}

.new-sidepanel-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.new-sidepanel-head h5 {
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	font-size: 1.1rem;
	height: 1.5rem;
	color: var(--sky-blue);
	margin: 0;
	height: 30px;
	align-items: center;
    display: flex;
}

.new-sidepanel-head-container-icons {
	display: flex;
	justify-content: right;
	align-items: center;
	height: 30px;
    font-size: 1rem;
}

.new-sidepanel-head-container-icons a,
.new-sidepanel-head-container-icons div  {
	margin-left: 0.8rem;
	height: 30px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.new-sidepanel-close-button {
	right: 1.0625rem;
	top: 0.6rem;
	display: flex;
	align-items: center;
	color: silver;
	text-decoration: none;
	height: 4rem;
	width: 4rem;
	background-image: url("/img/closeMenu.svg");
	background-size: 2.4rem;
	background-position: 0rem;
	background-repeat: no-repeat;
}

.new-sidepanel-tdh {
	display: flex;
}

.new-sidepanel-menu {
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	padding: 0;
	text-decoration: none;
}

.new-sidepanel-menu-child-item {
	display: flex;
	color: var(--black);
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 2rem;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-size: 0.9rem;
	line-height: 0.8;
	cursor: pointer;
	letter-spacing: 0.02rem;
	border-radius: 3px;
	padding: 0;
}

.new-sidepanel-menu-child-item:hover {
	color: #216497;
}

.new-sidepanel-social {
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	margin: 2rem 0rem;
	padding: 0;
}

.new-sidepanel-social-icons {
	font-family: IconFont;
	width: 2.2rem;
	height: 2.2rem;
	margin: 1rem 0.5rem 0 0;
	display: inline-block;
	color: #fff;
	border-radius: 100%;
	background-color: var(--black);
	display: flex;
	align-items: center;
	justify-content: center;
}

.new-sidepanel-social-item {
	display: inline-block;
	line-height: 1;
	font-weight: 400;
	font-size: 0.8rem;
	font-style: normal;
	text-decoration: inherit;
	text-transform: none;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}

.new-sidepanel-social-link {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.color-facebook {
	background-color: rgb(84, 109, 165);
}

.color-twitter {
	background-color: rgb(120, 187, 253);
}

.color-instagram {
	background-color: rgb(40, 40, 40);
}

.color-youtube {
	background-color: rgb(255, 0, 0);
}

.color-whatsapp {
	background-color: rgb(51, 153, 108);
}

.icon {
	width: 1rem;
	color: rgb(253, 253, 253);
}

.facebook-icon {
	width: 0.8rem;
}

.twitter-icon {
	width: 1.5rem;
}

.usability-icon {
	width: 1rem;
}

.close-usability-icon {
	width: 0.9rem;
}

@media (min-width: 650px) {
	.new-sidepanel-tdh {
		display: none;
	}
}

@media (max-width: 650px) {
	.new-sidepanel {
		width: 80vw;
		max-width: 80vw;
		padding-left: 1rem;
	}
	.new-sidepanel-head h5 {
		font-size: 1rem;
	}
	.new-sidepanel-head {
		margin-bottom: 2rem;
	}
	.new-sidepanel-menu-child-item {
		font-size: 0.9rem;
	}
}

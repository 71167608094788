.content-parent {
    align-items: baseline;
    display: flex;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    margin: 0 auto;
    font-weight: 400;
    width: 100%;
    max-width: 900px;
    justify-content: center;
}

.tag-title {
    flex: none;
    width: auto;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    color: var(--sky-blue);
	margin: 0;
}

.horizontal-scroll-wrapper {
    display: flex;
    overflow: auto;
    width: 100%;
    flex-flow: row nowrap;
    padding: 1rem 0;
    scroll-snap-type: x;
}

.content-li {
    display: inline-block;
    padding: 0 10px;
    flex: none;
    width: auto
}

.horizontal-scroll-wrapper::-webkit-scrollbar {
    height: 3px;
    width: 16px;
}
 
.horizontal-scroll-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--shadow-gray);
}
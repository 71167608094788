/*
	--screen-lg: 1300px;
	--screen-md: 1024px;
	--screen-sm: 980px;
	--screen-xs: 768px;
	--screen-mobile-md: 650px;
	--screen-mobile-sm: 480px;
*/

/* VARIABLES */

:root {
	--dark-black: #000;
	--medium-black: #282828;
	--black: #333333;
	--light-gray: #e1e1e1;
	--extralight-gray: #f4f4f4;
	--gray: #767676;
	--gray-text: #666666;
	--shadow-gray: #cccccc;
	--dark-shadow-gray: #999999;
	--bright-magenta: #e638c5;
	--white: #fff;
	--alert-red: #d91d00;
	--yellow: #ffb700;
	--extralight-blue: #BFE5E5;
	--light-blue: #4ea7f9;
	--sky-blue: #3a9fdc;
	--ocean-blue: #216497;
	--sky-gray: #f2f2f2;
	--orange: #f7a600;
	--orange-deep: #FF9300;
	--blue-facebook: #546da5;
	--blue-twitter: #78bbfd;
	--green-whatsapp: #33996c;
	--highlight-light-blue: #e6f3fb;
	--highlight-blue: #60c6f2;
	--turquoise-aqua: #98d3d4;
	--sponsored: #000000;
	--envivo: #e73232;
	--light-gray-s: #ebebeb;
	--extralight-gray-s: #f7f7f7;
}

.color-gray-charcoal {
	color: var(--gray-charcoal) !important;
}

.color-black {
	color: var(--black) !important;
}

.color-gray {
	color: var(--gray) !important;
}

.color-light-yellow {
	color: var(--light-yellow) !important;
}

.color-bright-magenta {
	color: var(--bright-magenta) !important;
}

.color-white {
	color: var(--white) !important;
}

* {
	box-sizing: border-box;
}

*.unselectable {
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;

	/*
	  Introduced in Internet Explorer 10.
	  See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
	*/
	-ms-user-select: none;
	user-select: none;
}

svg:not(:host).svg-inline--fa,
svg:not(:root).svg-inline--fa {
	overflow: visible;
	box-sizing: content-box;
}

.svg-inline--fa {
	display: var(--fa-display, inline-block);
	height: 1em;
	overflow: visible;
	vertical-align: -0.125em;
}

body {
	margin: 0 auto;
	background-color: var(--white);
	-webkit-font-smoothing: auto;
	line-height: 1.2;
	font-weight: 500;
	font-family: "Roboto", sans-serif;
}

body.no-scroll {
	overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
p {
	margin: 0.9375rem 0 0;
	font-weight: 500;
	letter-spacing: -0.3px;
}

a {
	cursor: pointer;
	text-decoration: none;
	color: inherit;
}

html {
	font-size: 16px;
}

@media (max-width: 980px) {
	html {
		font-size: 16px;
	}
}

@media (max-width: 650px) {
	html {
		font-size: 16px;
	}
}

/* END VARIABLES */

.orig-container {
	margin: 0;
	width: 100%;
	padding: 0;
}

.widget-title {
	font-family: "Roboto", sans-serif;
	font-size: 1.5rem;
	/* 24px */
	color: #000;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	margin-bottom: 1.1875rem;
}

.widget-title:before {
	content: "\FF0B";
	display: inline;
	color: var(--bright-magenta);
}

.headline-block-lead {
	font-size: 2.875rem;
	/* 46px */
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	line-height: 1.2;
	text-transform: capitalize;
	margin: 0.9375rem 0 0;
	color: var(--black);
	padding-bottom: 20px;
}

.headline-block-min-lead {
	font-size: 2.625rem;
	/* 42px */
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	line-height: 1.2;
	text-transform: capitalize;
	margin: 0;
	color: var(--black);
	padding-bottom: 20px;
}

.headline-divisor:after {
	content: "";
	display: block;
	height: 0.2rem;
	width: 2rem;
	background-color: var(--bright-magenta);
	margin: 0.625rem 0 0.9375rem;
}

.headline-text {
	color: var(--black);
	display: block;
	margin: 0.9375rem 0 0;
	margin: 15px 0;
	font-size: 2.625rem;
}

.other-headline-section {
	color: var(--black);
	display: block;
	margin: 0.9375rem 0 0;
	font-family: "Roboto", sans-serif;
	font-size: 1.875rem;
	/* 30px */
	font-weight: 500;
	text-transform: capitalize;
}

.headline-recomendation {
	color: var(--black);
	display: block;
	font-size: 1.5rem;
	/* 24px */
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	margin: 0;
}

.headline-lead-story {
	color: var(--black);
	display: block;
	font-size: 3rem;
	/* 48px */
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	margin: 0;
}

.by-line {
	color: var(--gray-charcoal);
	margin: 0.9375rem 0 0;
	-webkit-font-smoothing: auto;
	font-size: 0.75rem;
	/* 12px */
	line-height: 1.2;
	font-weight: 500;
	text-transform: uppercase;
}

.date-text {
	font-family: "Roboto", sans-serif;
	color: var(--gray);
	margin: 0.9375rem 0 0;
	-webkit-font-smoothing: auto;
	font-size: 0.75rem;
	/* 12px */
	line-height: 1.2;
	font-weight: 500;
}

.other-hook {
	color: var(--black);
	display: block;
	margin: 0;
	font-size: 1.0625rem;
	/* 17px */
	font-weight: 500;
}

.hook {
	color: var(--black);
	display: block;
	margin: 0.9375rem 0 0;
	font-size: 1.0625rem;
	/* 17px */
	font-weight: 500;
}

.seccion .headline-text {
	font-family: "Roboto", sans-serif;
	font-size: 1.0625rem;
	/* 17px */
}

.seccion .by-line {
	font-family: "Roboto", sans-serif;
}

.seccion .date-text {
	font-family: "Roboto", sans-serif;
}

.seccion .hook {
	font-family: "freight-text-pro", Georgia, Times, Serif;
}

/* ARTICLE */

.article .headline-text {
	font-size: 3.0625rem;
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	line-height: 120%;
}

.article .hook {
	font-family: "Roboto", sans-serif;
	font-size: 1.5rem;
	/* 24px */
	font-style: italic;
	text-transform: capitalize;
	font-weight: 500;
}

.article .text-content h1,
.article .custom-text h1 {
	margin: 0.9375rem 0 0.9375rem 0;
	font-size: 1.75rem;
	font-family: "SaolDisplay", Georgia, Times, Serif;
	font-weight: 500;
	text-transform: capitalize;
}

.article .text-content h2,
.article .custom-text h2 {
	margin: 1.5rem 0 0 0;
	font-size: 2rem;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
}

.article .text-content h3,
.article .custom-text h3 {
	margin: 1.5rem 0 0 0;
	font-size: 1.5rem;
	line-height: 1.2;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
}

.article .text-content h4,
.article .custom-text h4 {
	margin: 0.9375rem 0 0.9375rem 0;
	font-family: "Roboto", sans-serif;
	font-size: 1.125rem;
	line-height: 1;
	font-weight: 600;
	display: table;
	text-transform: uppercase;
	background-color: #f0ede5;
	padding: 0.9375rem 0.9375rem 0.5rem;
	letter-spacing: 0.1rem;
	position: relative;
}

.article .text-content p,
.article .custom-text p {
	font-family: "Roboto", sans-serif;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-size: 18px;
	font-weight: 400;
	line-height: 185%;
}

.banner-reserved-space {
	text-align: center;
	width: 100%;
	font-style: 'italic';
	color: var(--light-gray);
	font-weight: bold;
	min-height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: repeating-linear-gradient(45deg,
			#606dbc,
			#606dbc 10px,
			#465298 10px,
			#465298 20px);
}

/* UTILS */
.u-text-center {
	text-align: center !important;
}

.u-full-width {
	width: 100% !important;
}

.u-paddingHs {
	padding-top: 5px;
	padding-bottom: 5px;
}

.u-flex {
	display: flex !important;
}

.u-relative {
	position: relative;
}

.u-paddingNone {
	padding: 0 !important;
}

.u-max-width-100 {
	max-width: 100% !important;
}

.margin-top-divisor {
	margin-top: 3rem;
}

.u-display-block {
	display: block !important;
}

.u-grid {
	display: grid !important;
}

.u-uppercase {
	text-transform: uppercase !important;
}

.pie_foto_uminterior {
	display: none;
}

@media (max-width: 980px) {
	.article .headline-text {
		font-size: 2.875rem;
	}

	.headline-recomendation {
		font-size: 1.2rem;
	}

	.other-headline-section {
		font-size: 1.5rem;
	}

	.headline-block-lead {
		font-size: 2rem;
	}
}

@media (max-width: 768px) {
	.orig-container {
		padding: 0 20px;
	}
}

@media (max-width: 650px) {
	.article .headline-text {
		font-size: 1.7rem;
	}

	.orig-container {
		padding: 0;
	}

	.headline-block-min-lead {
		font-size: 2rem;
	}

	.article .text-content h3,
	.article .custom-text h3 {
		font-size: 1.5rem;
		line-height: 1.8rem;
		font-family: "Roboto", Arial, Sans-serif;
		font-weight: 500;
	}

	.article .text-content h2,
	.article .custom-text h2 {
		font-size: 1.6rem;
		line-height: 1.8rem;
		font-family: "Roboto", sans-serif;
		font-weight: 500;
	}

	.article .text-content h4 {
		font-family: "Gabor", Arial, Sans-serif;
		font-size: 1.125rem;
		line-height: 1;
		font-weight: lighter;
	}
}

/* CUSTOMS */

.custom-lilanews .lilanews-titlenews {
	font-weight: 600;
}

.custom-lilanews .lilanews-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	display: grid;
	grid-template-columns: 30% auto;
	column-gap: 1rem;
	margin-top: 1rem;
}

.custom-lilanews .lilanews-container h1,
.custom-lilanews .lilanews-container h2,
.custom-lilanews .lilanews-container h3,
.custom-lilanews .lilanews-container h4 {
	font-size: inherit;
	font-family: "Roboto", sans-serif;
}

.custom-lilanews .lilanews-container img {
	width: 100%;
}

.custom-lilanews .lilanews-title {
	font-size: 1.5rem;
	font-family: "Roboto", sans-serif;
	margin: 0;
}

.custom-lilanews .lilanews-description {
	font-size: 1rem;
	font-family: "Roboto", sans-serif;
	margin: 0;
}

.custom-lilanews .lilanews-text-container {
	justify-content: center;
	display: flex;
	flex-direction: column;
}

@font-face {
	font-family: "IconFont";
	src: url("https://assets.hearstapps.com/sites/elle/assets/fonts/IconFont.b32cffa1255ac0c29495bc00920714a1.woff2") format("woff2"),
		url("https://assets.hearstapps.com/sites/elle/assets/fonts/IconFont.b32cffa1255ac0c29495bc00920714a1.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

.instagram-media {
	width: 100%;
}

.instagram-media blockquote {
	width: 100%;
}

.ad-slot-caja {
	height: 250px;
	width: 300px;
	display: inline-block;
}

.ad-slot-caja_grande {
	height: 600px;
	width: 250px;
	display: inline-block;
}

.ad-slot-horizontal_chico {
	height: 90px;
	width: 728px;
	display: inline-block;
}

.ad-slot-horizontal_grande {
	height: 250px;
	width: 728px;
	display: inline-block;
}

.ad-slot-inline {
	height: 90px;
	width: 728px;
	display: inline-block;
}

@media screen and (max-width:1249px) {
	.ad-slot-inline {
		height: 250px;
		width: 300px;
	}
}

@media screen and (max-width:730px) {

	.ad-slot-horizontal_chico {
		height: 107px;
		width: 300px;
	}

	.ad-slot-horizontal_grande {
		height: 250px;
		width: 300px;
	}

	.ad-slot-inline {
		height: 250px;
		width: 300px;
	}

}

.banner-preview {
	overflow: hidden;
	max-width: 100%;
	display: flex;
	justify-content: center;
}

.banner-preview>div:first-child {
	min-height: 250px;
}

.parent-banner {
	display: flex;
	justify-content: center;
	overflow: hidden;
}

.ad-slot-background {
	background: repeating-linear-gradient(45deg, hsla(0, 0%, 90%, .1), hsla(0, 0%, 80%, .1), hsla(0, 0%, 90%, .1), hsla(0, 0%, 80%, .1) 4px, rgba(0, 0, 0, .15) 6px);
}

_::-webkit-full-page-media,
_:future,
:root .ad-slot-background {
	background: repeating-linear-gradient(45deg, hsla(0, 0%, 90%, .1), hsla(0, 0%, 80%, .1), hsla(0, 0%, 90%, .1), hsla(0, 0%, 80%, .1) 6px, rgba(0, 0, 0, .15) 8px);
}


.ad-slot-itt {
	height: 1px;
	width: 1px;
	display: none;
}

.ad-slot-zocalo {
	height: 1px;
	width: 1px;
	display: none;
}

.ql-align-center {
	text-align: center;
}

.ql-align-right {
	text-align: end;
}
.main-content {
    border: 1px solid var(--shadow-gray);
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    column-gap: 0.5rem;
    max-width: 100%;
    background-color: #f8f8f8;
    border-radius: 5px;
    font-size: 0.9rem;
    
}

.row-content {
    display: flex;
    padding: 1rem 0;
    border-top: 1px solid var(--shadow-gray);
}

.detail-content {
    align-items: center;
    flex-direction: column;
    flex: 1 0 0;
}

.row-content:nth-child(1) {
    border: 0;
}

.row-content:nth-child(1) .value {
    color: var(--light-blue);
    font-size: 1.2rem;
    font-weight: 600;
}
.row-content:nth-child(3) .detail-content {
    display: flex;
    flex-direction: column-reverse;
    font-size: 0.8rem;
    align-items: flex-start;
}

.row-content:nth-child(4) .detail-content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    font-size: 0.8rem;
}

.row-content .label {
    color: var(--gray-text);
}

.row-content:nth-child(4) .label { 
    margin-right: 5px;
}
.value {
    font-size: 1.6rem;
    font-weight: bold;
}

.row-content:nth-child(4) .value{ 
    font-size: .8rem!important;
    font-weight: bold;
    color: var(--light-blue);
  }
  .row-content div:nth-child(2) .value{ 
    color: var(--light-blue);
  }
a .boton-volver {
    display: flex;
    justify-content: center;
    align-items: center;   
    background-color: var(--light-blue);
    margin: auto;
    padding: .5rem 1.4rem;
    color: #FFF;
    border-radius: 8px;
    width: 80px;

}
.cotizaciones-firma {
    margin-top: .5rem;
    text-align: right;
    font-size: .7rem;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: .5rem;
}
.cotizaciones-firma img {
    width: 80px;
    height: auto;
    padding-right: 1rem;
}
.cotizaciones-byma {
    font-size: .7rem;
    color: #666;

}
.detail-content h1 {
    margin: 0;
}
@media (max-width: 768px) {
    .main-content {
        grid-template-columns: 1fr;
        margin: 0 1rem;
    }
}
.header-container {
	width: 100%;
	background-color: var(--white);
	margin:0 0 1.8rem 0;
	font-family: "Roboto", sans-serif;
  	font-size: 0.875rem;
	font-weight: 400;
	position: sticky;
	top: 0px;
	z-index: 200;
	padding:2rem 0;
	box-shadow: 0px 0px 15px rgba(0,0,0,.2);
}

.margin-header {
	max-width: calc(calc(var(--containerWidth) + var(--streetSizeH)));
	margin: 0 auto;
	padding: 0 calc(var(--streetSizeH) / 2);
}

.header {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
}

.nav-bar-row {
	max-height: 5rem;
	display: grid;
	grid-template-columns: 170px auto 170px;
	width: 100%;
}

.header-logo {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 2.6rem;
	padding-left: 1.32rem;
}

.logo {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.nav-bar {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	z-index: 1;
	width: 100%;
}

.weather-container {
	display: flex;
	align-items: center;
}

.weather-container .weather-img {
	width: 2rem;
}

.weather-container .weather-text {
	font-weight: 600;
	font-size: 1rem;
	margin-left: 5px;
}

.nav-bar a {
	margin-left: 0.5rem;
}

.nav-bar-menu-container {
	display: flex;
	align-items: stretch;
}

.nav-button {
	color: var(--dark-black);
	height: 2rem;
	min-width: 2rem;
}
.iconFontAwesome {
	font-size: 0.9rem;
	color: rgb(253, 253, 253);
}

.nav-bar-tdh {
	border-top: 1px solid var(--shadow-gray);
	border-bottom: 1px solid var(--shadow-gray);
	margin-top: 1rem;
}

.menu-desktop {
	background-color: var(--extralight-gray-s);
	border: 1px solid var(--light-gray-s);
	border-radius: 5px;
	display: flex;
	font-family: "Roboto", sans-serif;
 	font-size: 0.875rem;
	padding: 0.5rem;
	margin: 0.35rem 0 0 0;
	cursor: pointer;
}

.menu-mobile {
	display: none;
}

.visible {
  top: 0;
  transition: top 0.4s ease-out;
}

.hidden {
  top: -200px;
  transition: top 0.4s ease-out;
}

@media (max-width: 980px) {
	.menu-mobile {
		display: block;
		margin-top: 0.5rem;
	}

	.menu-desktop {
		display: none;
	}
}

@media (max-width: 768px) {
	.nav-bar .header-link {
		display: none;
	}

	.nav-bar-row {
		grid-template-columns: 100px auto 100px;
	}

	.nav-bar {
		width: auto;
	}
}

@media (max-width: 650px) {
	.header-container{
		margin:0 0 1rem 0;
		padding:1rem 0;
	}
	.nav-bar a{
		margin: 0;
	}
	.header-logo {
		height: 2rem;
		margin: auto;
	}
	.nav-bar-tdh {
		display: none;
		border-top: none;
	}
	.menu-mobile {
		margin-top: 0.25rem;
	}
}

@media (max-width: 480px) {
	.weather-container .weather-img{
		width: 1.5rem;
		height: 1.5rem;
	}
	.nav-bar-row {
		grid-template-columns: 40px auto 80px;
	}
	.header-logo {
		height: 2rem;
	}
}

.item {
  color: var(--black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .46rem 0;
  width: inherit;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  letter-spacing: 0.02rem;
  cursor: pointer;
}

.item:hover {
  color: var(--ocean-blue);
}

.item-submenu {
  padding-left: 1.4rem;
  list-style: none;
}

.chevron {
  display: inline-block;
  overflow: visible;
  box-sizing: content-box;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

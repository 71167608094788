.preview-containter-imagen{
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    border-top: 1px solid var(--shadow-gray);
    border-bottom: 1px solid var(--shadow-gray);
    padding: 2rem 0;
}
/* .container-imagen::after{
    content: '';
    position: absolute;
    width: 50%;
    left:25%;
    height: 2px;
    bottom: 0;
    background-color: var(--alert-red);
} */
.container-imagen {
border-bottom: 1px solid #CCC;
padding-bottom: .5rem;
}

/* CABEZAL */
#stories-principal-home .cabezal-wstories-seccion { background-color: #027dc3; height: 45px; padding: 8px 0;} 
#stories-principal-home .cabezal-wstories-seccion h1{color:#81b445; margin:0; padding:0;}
#stories-principal-home { background-color:rgba(242,245,245,0.8); margin:0 auto; padding: 0; } 
#stories-principal-home h1 a {color:#FFF; padding: 0; margin:0 0 0 10px;}
#stories-principal-home .item-wstories {font-size: 14px; text-align: left; height: 36px; line-height: 35px; padding: 0 2rem; margin: .74rem 0; text-transform: uppercase; vertical-align: middle; color: #000; cursor: pointer; text-decoration: none; min-width:100px; }
#stories-principal-home .item-wstories a { color:#282828; font-weight:500;}
#stories-principal-home .cabezal-wstories-seccion {background-color:#027dc3; height:59px; padding:8px 0;}
.menu-el-scroll li a { color: #333333!important; text-decoration: none;}    
#wstories_temas{padding: 0;}
#wstories_temas li {display: inline;}
#wstories_temas ul {display: inline;}
#wstories_temas li a { font-size: 14px; text-align: left; height: 36px; line-height: 35px; padding: 0 0.6rem; margin: .74rem 0; text-transform: uppercase; vertical-align: middle; color: #000; cursor: pointer; text-decoration: none; min-width: 100px; }
.menu-el-scroll {
    padding: 12px 0px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    overflow-x: hidden;
    scroll-behavior: inherit;
    display: block;
    /* overflow: scroll; */
    overflow-y: hidden;
}
.menu-el-scroll ul {
    margin: 0;
    padding: 0!important;
}
.menu-el-scroll li {
    /*display: inline;*/
    margin: 0px 25px 0 2px;
    padding: 7px;
    border-radius: 4px;
    font-weight: 600;
    position: relative;
    display: inline-block; /* Para ajustarse al tamaño de la imagen */
}  
.menu-el-scroll li img {
    width: 225px;
    height: 360px;

}  
.menu-el-scroll img {
    /*width: 225px;
    height: 360px;*/
    display: block; /* Evita espacios no deseados */
    width: 100%; /* Ajusta según necesidad */

}  
.titulo-tarjeta {
    font-size: 16px;
    width: 90%; 
    word-wrap: break-word; 
    word-break: break-word; 
    white-space: normal; 
    line-height: 1.4; 
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
}
   
.scroll-margin { margin: 10px 0;}
#wstories_temas{font-family:'roboto', sans;} 
.contenedor-principal-stories {width:100%;}
.contenedor-secundario-depo {width:20%; float: left;}
.contenedor-secundario2-depo {max-width: 1300px; margin: 0 auto;}
/*.menu-el-scroll  img {width: 225px; height: 360px;}*/
.ver-mas {display:none;}
/* CABEZAL */
@media only screen and (max-width: 1024px) {
.video-barra-vivo .caja_video { height:260px !important;}
.contenedor-principal-stories {width:100%;}
.contenedor-secundario-depo {width:100%;}
.contenedor-secundario2-depo {width:100%;}    
.menu-el-scroll {
    padding: 12px 0px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    overflow-x: hidden;
    scroll-behavior: inherit;
    display: block;
    overflow: scroll;
    overflow-y: hidden;
}
.menu-el-scroll li {
    /*display: inline;*/
    margin: 0px 25px 0 2px;
    padding: 7px;
    border-radius: 4px;
    font-weight: 600;
    position: relative;
    display: inline-block; /* Para ajustarse al tamaño de la imagen */
}  
.menu-el-scroll li img {
    width: 225px;
    height: 360px;

}  
.menu-el-scroll img {
    /*width: 225px;
    height: 360px;*/
    display: block; /* Evita espacios no deseados */
    width: 100%; /* Ajusta según necesidad */

}  
.titulo-tarjeta {
    font-size: 16px;
    width: 90%; 
    word-wrap: break-word; 
    word-break: break-word; 
    white-space: normal; 
    line-height: 1.4; 
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
}
        
}
@media only screen and (max-width: 480px) {
.video-barra-vivo .caja_video { height:170px !important;}
#wstories_temas ul li { display:none;}
#stories-principal-home {margin:0px 0;}
.menu-el-scroll {
    padding: 12px 0px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: normal;
    height: 786px;
    text-align: center;
    margin: 0 auto;
    background-color: #f7f7f7;
    overflow-y: hidden;
}
.ver-mas {max-width: 1300px; height: 50px; margin: 0; padding: 18px 10px 10px 10px; border-bottom: .0625rem solid #e4e4e4; text-align: left; font-size: 0.85rem; font-family:'roboto', sans; color: #000000; display: block; text-transform: uppercase;}
.ver-mas a {color: #000000; text-decoration: none;}
.ver-mas a:hover {color: #333333; text-decoration: none;}
.icon {transition: 0.3s ease-in-out;
    display: inline-flex;
    justify-content: center;
    align-items: center;
	width: 1rem;
	height: 1rem;
	border-radius: 50px;
	background-color: #3a9fdc;
	color: #ffffff;
}
.menu-el-scroll li {
    display: block;
    margin: 0px 8px 10px 2px;
    padding: 7px;
    border-radius: 4px;
    font-weight: 600;
    height: 364px;
}
.menu-el-scroll ul li:first-child {
    border-bottom: .0625rem solid #cccccc;
    border-radius: 0;
    height: 385px;
	}
    .menu-el-scroll ul {
        margin: 0;
        padding: 0!important;
    }
    .menu-el-scroll img {

        display: inline;
        width: 100%;
    
    } 
    .titulo-tarjeta {
        width:200px; 
    }
}
